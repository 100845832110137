<template>
    <!-- Sticky Navigation -->
    <nav class="nav-blur shadow-sm fixed w-full z-10 top-0 ">
        <div class="container mx-auto pl-6 pr-4 lg:px-0 lg:pr-6 py-4 flex justify-between items-center">
            <ApplicationLogo :color="'white'">
            </ApplicationLogo>
            <div class="hidden md:flex space-x-6 text-sm">
                <div v-if="canLogin" class="justify-end">

                </div>

            </div>
            <ShinyButton v-if="$page.props.auth.user" :href="route('headshots.index')">
                Your headshots
            </ShinyButton>
            <ShinyButton v-else :href="route('login')">
                Try it now
            </ShinyButton>

        </div>

    </nav>
</template>

<script>
import { Head, Link } from '@inertiajs/vue3';
import ShinyButton from '@/Components/ShinyButton.vue';
import ApplicationLogo from '@/Components/ApplicationLogo.vue';

export default {
    props: {
        canLogin: {
            type: Boolean,
            required: true
        },
        canRegister: {
            type: Boolean,
            required: true
        },

    },
    components: {
        Head,
        Link,
        ShinyButton,
        ApplicationLogo
    }
}
</script>

<style>
.gradient-text {
    background: linear-gradient(90deg, #0e4aba, #6e91c2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.scroll-container {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
}

.scroll-container::-webkit-scrollbar {
    display: none;
}

.main-heading {
    color: #f0f4ff;
    /* Closer to white with a tinge of dark navy */
}

.nav-blur {
    backdrop-filter: blur(10px);
}

@supports ((-webkit-backdrop-filter: initial) or (backdrop-filter: initial)) {
    #ac-localnav.ac-ln-sticking .ac-ln-background {
        -webkit-backdrop-filter: saturate(180%) blur(20px);
        backdrop-filter: saturate(180%) blur(20px);
    }
}

</style>