<template>
    <footer class=" bg-gray-900 text-gray-100 dark:bg-gray-950 dark:text-gray-100">
        <div class="container mx-auto w-full p-6 py-6 lg:py-8">
            <div class="md:flex md:justify-between">
                <div class="mb-6 md:mb-0">
                    <a href="/" class="flex items-center">
                        <span
                            class="self-center text-xl font-semibold whitespace-nowrap dark:text-gray-400">Headshot<span
                                class="text-blue-400">Online</span>.com</span>
                    </a>
                </div>
                <div class="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
                    <!-- <div>
      <h2 class="mb-6 text-sm font-semibold text-gray-100 uppercase dark:text-white">Resources</h2>
      <ul class="text-gray-100 dark:text-gray-400 font-medium">
          <li class="mb-4">
          <a href="#" class="hover:underline">Placeholder 1</a>
          </li>
          <li>
          <a href="#" class="hover:underline">Placeholder 2</a>
          </li>
      </ul>
      </div>
      <div>
      <h2 class="mb-6 text-sm font-semibold text-gray-100 uppercase dark:text-white">Follow us</h2>
      <ul class="text-gray-100 dark:text-gray-400 font-medium">
          <li class="mb-4">
          <a href="#" class="hover:underline">Placeholder 3</a>
          </li>
          <li>
          <a href="#" class="hover:underline">Placeholder 4</a>
          </li>
      </ul>
      </div> -->
                    <div>
                        <h2 class="mb-6 text-sm font-semibold text-gray-300 uppercase dark:text-white">Legal</h2>
                        <ul class="text-gray-400 dark:text-gray-300 font-medium">
                            <li class="mb-4">
                                <a :href="route('terms')" class="hover:underline">Terms and Conditons</a>
                            </li>
                            <li>
                                <a :href="route('privacy')" class="hover:underline">Privacy policy</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <hr class="my-6 border-gray-800 sm:mx-auto dark:border-gray-700 lg:my-8" />
            <div class="sm:flex sm:items-center sm:justify-between">
                <span class="text-sm text-gray-200 sm:text-center dark:text-gray-300">&copy; 2025
                    | All Rights Reserved.
                </span>

            </div>
        </div>
    </footer>
</template>